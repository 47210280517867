@import '../../styles/colors';
@import '../../styles/common';

.ScheduleSelector {
    .volume-list {
        font-size: 1.4rem;
        label {
            display: inline-block;
            cursor: pointer;
            width: 100%;
            span {
                display: inline-block;
                padding: 1em;
                border: 1px solid $neutral;
                width: 100%;
            }
        }
        input[type=radio] {
            display: none;
        }
        input[type=radio]:checked + span {
            background-color: #33c3f0;
        }
    }
    .calendar-container {
        position: relative;
        td, th {
            padding: 9px 3.9px;
        }
    }

    .react-datepicker {
        button {
            border-top-color: transparent;
            border-bottom-color: transparent;
        }
    }

    .react-datepicker__navigation--previous {
        border-left-color: transparent;
    }

    .react-datepicker__navigation--next {
        border-right-color: transparent;
    }

    .calendars {
        margin-bottom: 1em;
    }
}